<template>
  <div v-if="pageData !== null" class="economia-page">
    <v-layout row wrap style="background: #efefef">
      <v-flex xs12 sm12 md6>
        <div class="logo" style="background: #efefef">
          <a href="https://enercred.com.br/">
            <img id="logo-color" class="d-inline-block" src="@/assets/img/logo.svg" scale="0" />
          </a>
          <a :href="pageData.url || 'https://www.bancobv.com.br/'">
            <img
              style="width: 70px;"
              class="d-inline-block ml-5"
              v-if="pageData.logoUrl"
              :src="pageData.logoUrl"
              scale="0"
            />
          </a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm12
        md6
        class="text-xs-right hidden-sm-and-down"
        style="padding-top: 20px; padding-right: 96px"
      >
        <v-btn
          class="enercred-logo_btn"
          color="success"
          outline
          type="submit"
          href="https://enercred.com.br/simulador"
          target="_blank"
        >Simular economia</v-btn>
      </v-flex>
    </v-layout>
    <div class="bg--white">
      <section class="enercred-assinatura">
        <v-container fluid grid-list-md fill-height>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <div class="enercred-assinatura_left text-xs-center">
                <iframe
                  style="border-radius: 5px"
                  class="enercred-assinatura_iframeContainer"
                  :src="video || 'https://www.youtube.com/embed/3bDvd42Mt9M'"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md6 class="enercred-assinatura_rigth">
              <div class="enercred-assinatura_right text-xs-center">
                <h1
                  class="mb--micro title text--jumbotron text--center lg:text--left"
                >{{firstnameUpper + pageData.tagline}}</h1>
                <p
                  class="text--large mb--small text--center lg:text--left"
                >{{pageData.subtagline || 'Utilizando Energia Solar. Sem investimento e sem obras.'}}</p>
                <v-btn
                  class="enercred-assinatura_btn"
                  :color="cta.color || 'success'"
                  round
                  large
                  type="submit"
                  @click.prevent="goToSign"
                >{{cta.text.toUpperCase() || 'ASSINAR AGORA'}}</v-btn>
                <div style="text-align: center; width:100%">
                  <cpnWhatsappButton class="hidden-sm-and-down" :whatsapp_text="whatsapp"/>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md12 class="hidden-sm-and-up" style="text-align: right">
              <cpnWhatsappButton :whatsapp_text="whatsapp"/>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <section class="enercred-beneficios">
        <!-- <v-container fluid grid-list-md fill-height> -->
        <v-layout row align="center" justify="center" wrap>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="enercred-beneficios_left">
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs12 sm12 md12 style="text-align: center">
                  <h2>Energia Limpa para você</h2>
                </v-flex>
              </v-layout>
              <div v-if="!beneficios || beneficios.length <= 0">
                <v-layout row align="center" class="enercred-beneficios_line" wrap>
                  <v-flex xs2 sm2 md2 style="text-align: center">
                    <v-icon class="enercred-beneficios_icon" large>mdi-office-building</v-icon>
                  </v-flex>
                  <v-flex
                    xs10
                    sm10
                    md10
                    style="text-align: left"
                  >Para quem mora em casa, apartamento ou de aluguel</v-flex>
                </v-layout>
                <v-layout row align="center" class="enercred-beneficios_line" wrap>
                  <v-flex xs2 sm2 md2 style="text-align: center">
                    <v-icon class="enercred-beneficios_icon" large>mdi-circle-slice-7</v-icon>
                  </v-flex>
                  <v-flex
                    xs10
                    sm10
                    md10
                    style="text-align: left"
                  >Você aluga um percentual de nossa usina e nós cuidamos para que o desconto chegue em sua conta de luz.</v-flex>
                </v-layout>
                <v-layout row align="center" class="enercred-beneficios_line" wrap>
                  <v-flex xs2 sm2 md2 style="text-align: center">
                    <v-icon class="enercred-beneficios_icon" large>mdi-credit-card</v-icon>
                  </v-flex>
                  <v-flex
                    xs10
                    sm10
                    md10
                    style="text-align: left"
                  >Comodidade e segurança: Faturamos no seu cartão de crédito apenas após receber o desconto em sua conta de energia.</v-flex>
                </v-layout>
                <v-layout row align="center" class="enercred-beneficios_line" wrap>
                  <v-flex xs2 sm2 md2 style="text-align: center">
                    <v-icon class="enercred-beneficios_icon" large>mdi-solar-power</v-icon>
                  </v-flex>
                  <v-flex
                    xs10
                    sm10
                    md10
                    style="text-align: left"
                  >E ainda você ajuda o planeta usando energia 100% Solar.</v-flex>
                </v-layout>
              </div>
              <div v-if="beneficios && beneficios.length > 0">
                <v-layout row align="center" class="enercred-beneficios_line" wrap 
                  v-for="(item,i) in beneficios"
                  :key="i"
                >
                  <v-flex xs2 sm2 md2 style="text-align: center">
                    <v-icon class="enercred-beneficios_icon" large>{{item.icon}}</v-icon>
                  </v-flex>
                  <v-flex
                    xs10
                    sm10
                    md10
                    style="text-align: left"
                  >
                    <div v-html="item.text"/>
                  </v-flex>
                </v-layout>
              </div>
              <v-layout row align="center" class="enercred-beneficios_line" wrap>
                <v-flex xs12 sm12 md12 style="text-align: center">
                  <h3>Corra! As vagas são limitadas.</h3>
                    <v-btn
                    class="enercred-assinatura_btn"
                    :color="cta.color || 'success'"
                    round
                    large
                    type="submit"
                    @click.prevent="goToSign"
                  >{{cta.text.toUpperCase() || 'ASSINAR AGORA'}}</v-btn>
                  <!-- <v-btn
                    class="enercred-assinatura_btn"
                    color="success"
                    round
                    large
                    type="submit"
                    @click.prevent="goToSign"
                  >ASSINAR AGORA</v-btn> -->
                </v-flex>
                <v-flex xs12 sm12 md12 class="hidden-sm-and-up" style="text-align: right">
                  <cpnWhatsappButton :whatsapp_text="whatsapp"/>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="enercred-beneficios_right">
              <v-carousel height="100vh">
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <div class="carousel_name">
                    <p>{{item.name}}</p>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-flex>
        </v-layout>
        <!-- </v-container> -->
      </section>
      <section class="enercred-clientes">
        <!-- <v-container fluid grid-list-md fill-height> -->
        <v-layout row wrap>
          <v-flex xs12 sm12 md12>
            <div class="enercred-clientes_left text-xs-center">
              <h2
                class="mb--micro text--jumbotron text--center lg:text--left"
              >Veja o que nossos clientes estão falando...</h2>
              <iframe
                class="enercred-clientes_videos"
                src="https://www.youtube.com/embed/aTF-JUDkk08"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <iframe
                class="enercred-clientes_videos"
                src="https://www.youtube.com/embed/0PVzZVgoolM"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <iframe
                class="enercred-clientes_videos"
                src="https://www.youtube.com/embed/JKsy6lf3Cl4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <v-btn
                class="enercred-clientes_btn"
                color="blue"
                outline
                round
                large
                type="submit"
                @click.prevent="goToSign"
              >{{cta.text}}</v-btn>
            </div>
          </v-flex>
        </v-layout>
        <!-- </v-container> -->
      </section>
      <section class="enercred-footer">
        <!-- <v-container fluid grid-list-md fill-height> -->
        <v-layout row wrap>
          <v-flex xs12 sm12 md12>
            <div class="text-xs-center">Enercred © 2020</div>
          </v-flex>
        </v-layout>
        <!-- </v-container> -->
      </section>
    </div>
    <!-- <cpnWhatsApp/> -->
  </div>
</template>
<script>
import { saveAnalytics } from "@/services/save_analytics"
import { firestore } from "../../firebase/firestore"
// import WhatsApp from '../../components/Whatsapp'
import WhatsappButton from '../../components/core/WhatsappButton'

export default {
  data() {
    return {
      referral_code: "",
      items: [
        {
          src: "https://firebasestorage.googleapis.com/v0/b/enercred.appspot.com/o/web%2Fsafiravmf_jul2020.jpeg?alt=media&token=3bf11694-6c12-43e3-8f98-498e44f06c5b",
          name: "Usina Solar SafiraVMF (5MWp)"
        },
        {
          src: "https://www.enercred.com.br/wp-content/themes/enercred-1/assets/min-images/about/stations/1.jpg",
          name: "Usina Solar Pedralva II"
        },
        {
          src: "https://www.enercred.com.br/wp-content/themes/enercred-1/assets/min-images/about/stations/2.jpg",
          name: "Usina Solar Pedralva II"
        },
        {
          src: "https://www.enercred.com.br/wp-content/themes/enercred-1/assets/min-images/about/stations/4.jpg",
          name: "Planta Solar Pedralva I"
        },
      ],
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      firstname: '',
      pageData: null,
      beneficios: [],
      cta: null,
      video: '',
      whatsapp: {
        text: '',
        subtext: ''
      }
    }
  },
  components: {
    cpnWhatsappButton: WhatsappButton
  },
  created() {
    let url = window.location.origin
    console.log(this.$route.query)
    let subdomain = url.slice(url.lastIndexOf("/") + 1).split(".")[0]
    this.firstname = this.$route.query?this.$route.query.name:''

    if (subdomain !== "onboard" && subdomain !== "localhost:8080") {
      // console.log('substring: ', subdomain.substring(0,5))
      if(subdomain.substring(0,5) === 'radio' || subdomain === 'saibamais') {
        firestore
          .doc("partners/enercred")
          .get()
          .then(partnerPageData => {
            this.pageData = partnerPageData.data().style.economia
            this.beneficios = partnerPageData.data().style.beneficios
            this.cta = partnerPageData.data().style.cta
            this.video = partnerPageData.data().style.video
            if(partnerPageData.data().style.whatsapp)
              this.whatsapp = partnerPageData.data().style.whatsapp
          })
        
        this.utm_source = subdomain.substring(0,5) === 'radio'?'radio':subdomain
        localStorage.setItem("utm_source", this.utm_source)
        
        this.utm_medium = subdomain.substring(0,5) === 'radio'?'radio':subdomain
        localStorage.setItem("utm_medium", this.utm_medium)

        this.utm_campaign = subdomain
        localStorage.setItem("utm_campaign", this.utm_campaign)
      } else {
        // get partner page data from firestore
        firestore
          .doc("partners/" + subdomain)
          .get()
          .then(partnerPageData => {
            this.pageData = partnerPageData.data().style.economia
            this.beneficios = partnerPageData.data().style.beneficios
            this.cta = partnerPageData.data().style.cta
            this.video = partnerPageData.data().style.video
            if(partnerPageData.data().style.whatsapp)
              this.whatsapp = partnerPageData.data().style.whatsapp
          })
      }
    } else {
      firestore
        .doc("partners/enercred")
        .get()
        .then(enercredPage => {
          this.pageData = enercredPage.data().style.economia
          this.beneficios = enercredPage.data().style.beneficios
          this.cta = enercredPage.data().style.cta
          this.video = enercredPage.data().style.video
          if(enercredPage.data().style.whatsapp)
              this.whatsapp = enercredPage.data().style.whatsapp
        })
    }
    this.isProd = process.env.NODE_ENV === "production"
    if (this.$route.query.referral_code) {
      this.referral_code = this.$route.query.referral_code
      // console.log("referral_code: ", this.referral_code)
    }
    if (this.isProd) {
      const analytics = {
        eventCategory: "onboard",
        eventAction: "VIEWED_LP_ONBOARDING",
        eventLabel: "VIEWED_LP_ONBOARDING",
        value: "",
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
      }
      saveAnalytics(analytics)
    }
    this.getUTM()
  },
  computed: {
    firstnameUpper() {
      if(!this.firstname || this.firstname === '') return ''
      return this.firstname.charAt(0).toUpperCase() + this.firstname.slice(1) + '! '
    }
  },
  methods: {
    getCookie(cname) {
      var name = cname + "="
      var ca = document.cookie.split(";")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    getUTM() {
      if (this.$route.query.utm_source) {
        this.utm_source = this.$route.query.utm_source
        localStorage.setItem("utm_source", this.utm_source)
        // } else if (window.$cookies.isKey('utm_source')) {
      } else if (this.getCookie("utm_source")) {
        this.utm_source = this.getCookie("utm_source")
        localStorage.setItem("utm_source", this.utm_source)
      } else if (localStorage.getItem("utm_source")) {
        this.utm_source = localStorage.getItem("utm_source")
      } else {
        this.utm_source = "direct"
      }

      if (this.$route.query.utm_medium) {
        this.utm_medium = this.$route.query.utm_medium
        localStorage.setItem("utm_medium", this.utm_medium)
        // } else if (window.$cookies.isKey('utm_medium')) {
      } else if (this.getCookie("utm_medium")) {
        this.utm_medium = this.getCookie("utm_medium")
        localStorage.setItem("utm_medium", this.utm_medium)
      } else if (localStorage.getItem("utm_medium")) {
        this.utm_medium = localStorage.getItem("utm_medium")
      } else {
        this.utm_medium = "direct"
      }

      if (this.$route.query.utm_campaign) {
        this.utm_campaign = this.$route.query.utm_campaign
        localStorage.setItem("utm_campaign", this.utm_campaign)
        // } else if (window.$cookies.isKey('utm_campaign')) {
      } else if (this.getCookie("utm_campaign")) {
        this.utm_campaign = this.getCookie("utm_campaign")
        localStorage.setItem("utm_campaign", this.utm_campaign)
      } else if (localStorage.getItem("utm_campaign")) {
        this.utm_campaign = localStorage.getItem("utm_campaign")
      } else {
        this.utm_campaign = "direct"
      }
    },
    goToSign() {
      //   this.$router.replace({"cadastro-assinatura", );
      if (this.referral_code) {
        this.$router.push({ path: "cadastro", query: { referral_code: this.referral_code, ...this.$route.query } })
      } else {
        this.$router.push({ path: "cadastro", query: this.$route.query })
      }
    }
  }
}
</script>
<style scoped>
.logo {
  background-color: #fff;
  padding: 10px 30px;
  align-items: center;
  text-align: center;
}

#logo-color {
  max-width: 130px;
  width: 130px;
}

p {
  margin: 0;
}

.economia-page {
  background-color: #fff;
  color: #2b2f45;
  font-family: "Poppins", "Helvetica Neue", Calibre, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  /* padding-left: 32px;
        padding-right: 32px; */
}

.economia-page .bg--white {
  background-color: #fff;
  height: 100vh;
}

.enercred-assinatura {
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-direction: column; */
  flex-direction: column;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.enercred-assinatura_iframeContainer {
  max-width: 560px;
  width: 100%;
  height: 199px;

  /* margin-bottom: -8%; */
}

.enercred-assinatura_btn {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 90%;
}

.economia-page .text--center {
  text-align: center;
}
.economia-page .text--jumbotron {
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
}

.economia-page .text--jumbotron,
.economia-page h1,
.economia-page .h1,
.economia-page h2,
.economia-page .h2,
.economia-page h3,
.economia-page .h3,
.economia-page h4,
.economia-page .h4,
.economia-page h5,
.economia-page .h5,
.economia-page h6,
.economia-page .h6 {
  color: #2b2f45;
  font-family: Poppins, "Helvetica Neue", Calibre, Helvetica, Arial, sans-serif;
}

.economia-page .mb--micro {
  margin-bottom: 8px !important;
}

.economia-page .text--large {
  font-size: 26px;
  line-height: 32px;
}

.economia-page .mb--small {
  margin-bottom: 16px !important;
}

.enercred-beneficios {
  background-color: #efefef;
  min-height: 100vh;
  display: -ms-flexbox;
  display: grid;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  /* background-color: #A4C425;  */
  color: #506670;
  font-size: 20px;
  /* padding-right: 32px;
        padding-left: 32px; */
  align-items: center;
}

.enercred-beneficios_left {
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.enercred-beneficios_img {
  padding-top: 100px;
  bottom: 0px;
}

.enercred-beneficios_icon {
  color: #a4c425;
}

.enercred-beneficios_line {
  padding: 15px 5px;
}

.enercred-clientes {
  /* background-color: #00a8bb; */
  background-color: #fff;
  /* color: #fff !important; */
  color: #506670;
  padding: 10px;
  /* color: #fff !important; */
  width: 100%;
  /* height: 100vh; */
  /* max-width: 1200px; */
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-direction: column; */
  flex-direction: column;
  /* -ms-flex-pack: center; */
  justify-content: center;
  font-size: 23px;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
}

.enercred-clientes_btn {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 90%;
}

h1 {
  font-size: 2rem !important;
  font-weight: 900;
}

h2 {
  font-size: 1.4rem !important;
}

p {
  font-size: 1.2rem !important;
}

.carousel_name {
  position: relative;
  top: 85%;
  background-color: #00a8bb;
  color: #fff;
  width: 65%;
  height: 30px;
  align-content: center;
}

.carousel_name p {
  vertical-align: middle;
  padding: 5px;
  font-size: 14px !important;
  font-weight: 400;
  height: 100%;
}

.enercred-footer {
  background-color: #506670;
  color: #fff;
  height: 10vh;
  font-weight: 500;
  padding: 3vh;
}

@media (min-width: 768px) {
  h2 {
    font-size: 2rem !important;
  }

  .logo {
    background-color: #fff;
    padding: 15px 96px;
    align-items: left;
    text-align: left;
  }

  #logo-color {
    max-width: 150px;
    width: 150px;
  }
  .enercred-assinatura {
    padding-left: 32px;
    padding-right: 32px;
  }

  .enercred-beneficios {
    background-color: #efefef;
    min-height: 100vh;
    display: -ms-flexbox;
    display: grid;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    /* background-color: #A4C425;  */
    color: #506670;
    font-size: 23px;
    /* padding-right: 32px;
          padding-left: 32px; */
    align-items: center;
  }

  .carousel_name {
    position: relative;
    top: 85%;
    background-color: #00a8bb;
    color: #fff;
    width: 40%;
    height: 30px;
    align-content: center;
  }

  .carousel_name p {
    vertical-align: middle;
    padding: 5px;
    font-size: 14px !important;
    font-weight: 400;
    height: 100%;
  }

  .enercred-clientes {
    /* background-color: #00a8bb; */
    padding: 64px;
    /* color: #fff !important; */
    background-color: #fff;
    /* color: #fff !important; */
    color: #506670;
    width: 100%;
    /* height: 100vh; */
    /* max-width: 1200px; */
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-direction: column; */
    flex-direction: column;
    /* -ms-flex-pack: center; */
    justify-content: center;
    font-size: 23px;
    /* padding-right: 15px; */
    /* padding-left: 15px; */
  }

  .enercred-clientes_rigth {
    padding-left: 15px;
  }

  .enercred-clientes_videos {
    padding: 10px;
    width: 33%;
    height: 247px;
  }
  .enercred-clientes_btn {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .enercred-assinatura {
    /* min-height: 100vh; */
    padding-top: 50px;
    padding-bottom: 50px;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }

  .enercred-assinatura_left {
    text-align: center;
    padding-right: 15px;
  }

  .enercred-assinatura_right {
    padding-left: 15px;
  }

  .enercred-assinatura_iframeContainer {
    width: 100%;
    max-width: 560px;
    height: 315px;
    /* margin-bottom: -8%; */
  }

  .enercred-assinatura_btn {
    width: 30%;
  }

  .enercred-assinatura_textContainer {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .enercred-assinatura_text {
    max-width: 568px;
  }

  /* .enercred-assinatura_imgContainer, .enercred-assinatura_textContainer {
            width: 50%;
        } */

  /* .enercred-assinatura_imgContainer, .enercred-assinatura_text {
            width: 50%;
        } */

  .economia-page .lg\:text--left {
    text-align: left;
  }

  .economia-page .text--large {
    font-size: 24px !important;
    line-height: 32px;
  }

  .enercred-beneficios_left {
    align-items: center;
    padding: 40px 32px;
    padding-left: 32px;
    justify-content: center;
  }
  .enercred-beneficios_right {
    padding-left: 32px;
  }

  h1 {
    font-size: 2.9rem !important;
  }

  p strong {
    /* font-size: 2.9rem !important; */
    font-weight: 900;
  }
}
</style>